import React from 'react';
import { useEffect, useState } from "react";
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';

function Dashboard() {
    useEffect(() => {
        document.title = "Dashboard - GoToSample"
    },);
    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Dashboard</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            
                            <div className="row text-center mt-5">
                                <div className="col-md-12">
                                    <h3>Welcome to GoToSample Dashboard</h3>
                                </div>
                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer /> 
        </div>
    )
}

export default Dashboard;