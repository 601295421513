import React from 'react'
import { postAPI } from '../../services/ApiService'
import { useNavigate } from "react-router-dom";
import { validateToken } from '../../api/auth';
import { useEffect } from 'react';

function Header() {
    const navigate = useNavigate();
    const userDetail = JSON.parse(localStorage.getItem('userDetail'))
    useEffect(() => {
        let checkLogin = localStorage.getItem('token')
        if(!checkLogin && !userDetail){
            navigate("/");
        }
        // checkToken()
    })
    const checkToken = async () =>{
        let res = await validateToken();
        if(res.status === 0){
            localStorage.clear();
            navigate("/");
        }
    }
    const logout = async()=>{
        checkToken();
        let res = await postAPI('/auth/logout',{})
        if(res.status === 1){
            localStorage.clear();
            navigate("/");
        }
    }    
  return (
    <>
        <div id="layout-wrapper">
            <header id="page-topbar">
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            {/* <!-- LOGO --> */}
                            <div className="navbar-brand-box horizontal-logo">
                                <a href="index-2.html" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src="/assets/images/logo-sm.png" alt="" height="22"/>
                                    </span>
                                    <span className="logo-lg">
                                        <img src="/assets/images/logo-dark.png" alt="" height="17"/>
                                    </span>
                                </a>

                                <a href="index-2.html" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src="/assets/images/logo-sm.png" alt="" height="22"/>
                                    </span>
                                    <span className="logo-lg">
                                        <img src="/assets/images/logo-light.png" alt="" height="17"/>
                                    </span>
                                </a>
                            </div>
                        </div>

                        <div className="d-flex align-items-center">

                            <div className="dropdown d-md-none topbar-head-dropdown header-item">
                                <button type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="bx bx-search fs-22"></i>
                                </button>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-search-dropdown">
                                    <form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username"/>
                                                <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                    

                            <div className="dropdown ms-sm-3 header-item topbar-user">
                                <button type="button" className="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span className="d-flex align-items-center">
                                        {/* <img className="rounded-circle header-profile-user" src="/assets/images/users/avatar-1.jpg" alt="Header Avatar"/> */}
                                        <span className="text-start ms-xl-2">
                                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text"> </span>
                                            {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> */}
                                        </span><i className="mdi mdi-account-circle text-muted fs-8_profile mx-2 align-middle me-1"></i>
                                    </span>
                                </button>
                                <div className="dropdown-menu dropdown-menu-end">
                                    {/* <!-- item--> */}
                                    <span className="d-flex align-items-center dropdown-item bg-primary">
                                        <img className="rounded-circle header-profile-user" src="/assets/images/users/user-dummy-img.jpg" alt="Header Avatar"/>
                                        <span className="text-start ms-xl-2">
                                            {/* <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text-p color-white">GotoSample </span> */}
                                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text-p user-name-text-p">
                                                {userDetail?.firstName + " " + userDetail?.lastName}
                                            </span>
                                        </span>
                                    </span>
                                    
                                    {/* <a className="dropdown-item" href=""><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Profile</span></a> */}
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" style={{cursor:"pointer"}} onClick={() => logout()}><i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span className="align-middle" data-key="t-logout">Logout</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    </>
  )
}

export default Header