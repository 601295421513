import React from 'react'
import { useEffect, useState } from "react";
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar'; 
import Pagination from '../Layout/Pagination';
import Swal from "sweetalert2";

import { putAPI,postAPI, get, uploadImageAPI } from '../../services/ApiService';

function Role() {
    useEffect(() => {
        document.title = "Role - GoToSample"
    },);
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState({});
    const [modules, setModules] = useState([]);
    const [actions, setActions] = useState([]);
    const [pageInfo, setPagination] = useState({});
    const [moduleDisplay, setModuleDisplay] = useState(false);
    
    
    
    const getList = async(page=1) => {
        let data = {page: pageInfo.current_page,recPerPage: 50, q: "",func:''}
        let res = await get('/urm/role/list', data);        
        if(res.status === 1){
            // let data = res;//.json();
            let respArr = [];
                respArr = res.payload;
                respArr.forEach((r, index) => { r.serial = index + 1; });
            
                setRoles(respArr);
                setPagination(res.paginationInfo);
        }
    }

    useEffect(() => {
        getList()
    }, [])

    const changePage =(p)=>{
        pageInfo.current_page = p;
        getList();
    }


const submitRole = async (e) => {
    // e.preventDefault();
    //setErrors();
    let name = document.getElementById('roleName').value;
    //let imageURL = document.getElementById('roleFileUrl').value;
    let formData = {
        name:name,
       // imageURL:imageURL
    };
    // const formErrors = validateFormData(formData)
    // if(Object.keys(formErrors).length > 0){
    //     //setErrors(formErrors)
    // }else{
        let res = {};
        if(document.getElementById('roleId').value == ""){
            res = await postAPI('/urm/role/add/', formData);
        }else{
            let roleId = document.getElementById('roleId').value; 
            res = await putAPI('/urm/role/update/'+roleId, formData);
        }
        if(res.status === 1){
            setRoleFields();
            getList();           
         }
        //else{
        //     //setErrors({...errors,['submit']:res.message})
        // }
    //}
}

const setRoleFields = (id,name,url)=>{
    document.getElementById('roleId').value = id?id:'';
    document.getElementById('roleName').value = name?name:'';
    if(!id){
        document.getElementById('cancelButton').style.display = 'none';
        
    }else{
        document.getElementById('cancelButton').style.display = 'inline-block';
    }
}


const updateRoleStatus = async (rolId,status) => {
    // e.preventDefault();
    //setErrors();
    status = status==0?1:0;
    let formData = {
        id:rolId,
        status:status
    };
    // const formErrors = validateFormData(formData)
    // if(Object.keys(formErrors).length > 0){
    //     //setErrors(formErrors)
    // }else{
        let res = await putAPI('/urm/role/update-status/'+rolId+'/'+status, formData);
        if(res.status === 1){
            getList();           
         }
        //else{
        //     //setErrors({...errors,['submit']:res.message})
        // }
    //}
}

const getModules = async(page=1) => {
    let data = {page: pageInfo.current_page,recPerPage: 500, q: "",func:''}
    let res = await get('/urm/module/list', data);        
    if(res.status === 1){
            setModules(res.payload);
    }
}

const getActions = async(page=1) => {
    let data = {page: pageInfo.current_page,recPerPage: 500, q: "",func:''}
    let res = await get('/urm/action/list', data);        
    if(res.status === 1){
            setActions(res.payload);
    }
}

useEffect(() => {
    getModules();
    getActions();
}, [])

const checkAll= async(e) =>{
    let actions =  document.getElementsByClassName("actions_"+e.target.value);
    if(e.target.checked){
        for(let i=0; i < actions.length; i++){
            actions[i].checked = true;
        }       
    }else{
        for(let i=0; i < actions.length; i++){
            actions[i].checked = false;
        } 
    }
}
const submitPermission = async(e)=>{
    e.preventDefault();
    const formData = new FormData(e.target);
    let formInput = {};
    for (const [key, value] of formData) {
        formInput[key] = value;
    }

    let res = await postAPI('/urm/role/save-permission', formInput);
    if(res.status === 1){
        getList();   
        return Swal.fire({
            text:res.message,
            timer: 2000,
        })        
     }

}







    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Add Role</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                {/* <li className="breadcrumb-item"><a >Dashboards</a></li> */}
                                                <li className="breadcrumb-item active">Add Role</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <input type="hidden" className="form-control" id="roleId"  />

                                        <label className="form-label mb-0">Role </label>
                                        <input type="text" className="form-control" placeholder="Enter role name" id="roleName" />
                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Image (Dimension: 200 X 200 pixels)</label>
                                        <input type="file" className="form-control" placeholder="type role image" id="roleFile" onChange={handleChange} multiple />
                                        <input type="hidden" className="form-control" id="roleFileUrl"  />
                                    </div>
                                </div> */}
                                <div class="col-md-4">
                                    <img class="mt-3" id="imageView" style={{display:'none',height:"50px", border:"2px solid grey"}} src ="" />
                                    <div class="mt-3" style={{display:'inline-block'}}>
                                        <label> &nbsp; </label>
                                        <button type="button" class="btn btn-primary mx-2" onClick={()=>submitRole()}>Submit</button>
                                    </div>
                                    <div class="mt-3" style={{display:'inline-block'}}>
                                        <label> &nbsp; </label>
                                        <button type="button" className="btn btn-danger mx-2" id="cancelButton" style={{display:'none'}} onClick={()=>setRoleFields()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <table class="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr class="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">#</th>
                                                        {/* <th scope="col">Image</th> */}
                                                        <th scope="col">Role Name</th>
                                                        {/* <th scope="col">User Count</th> */}
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                {roles.length > 0 && (
                                                <tbody>
                                                    {roles.map((role,index) => (
                                                        <tr key={role.id}>
                                                        <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                        {/* <td><img style={{width:'100px'}} src={s3bucket+role.description.icon} /></td> */}
                                                        <td>{role.name}</td>
                                                        {/* <td>{role.productCount}</td> */}
                                                        <td>
                                                            <div class="form-check form-switch" style={{textAlign:"center"}}>
                                                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={role.status==1?"checked":""} onClick={()=>updateRoleStatus(role.id,role.status)} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-sm" onClick={()=>setRoleFields(role.id,role.name)}>Edit </button> &nbsp; 
                                                            <button class="btn btn-sm btn-info" onClick={()=>{setRole(role);setModuleDisplay(true)}}>Permission </button>
                                                            
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                                )}
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end row--> */}
                            {/* <!-- end row --> */}
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />

            {moduleDisplay === true ? (<>
                <>
            <CModal visible={moduleDisplay} backdrop={"static"} size={"xl"} onClose={() => setModuleDisplay(false)} id='surveyModal'>
                <CModalHeader style={{ backgroundColor: "#405189", paddingBottom: "10px", margin: "-1px" }} closeButton={false} >
                    <CModalTitle style={{ color: "#fff", width: "100%" }}>
                    Manage Permission of Role - {role.name}
                        <span style={{ float: "right", cursor: "pointer" }} onClick={() => setModuleDisplay(false)} > X </span>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <form  id="surveyForm" className='row' onSubmit={submitPermission}>
                    {/* <div id='point-message' style={{color:"red"}}>{errors?.saveSurvey}</div> */}
                    <input type='hidden' name="roleID" value={role.id}/>

                    {
                        modules.map(module=>{
                            return(
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <input type="checkbox" id={"module-"+module.id} value={module.id} onClick={(e)=>checkAll(e)} defaultChecked={
                                        (role.permissions.find((element) => element.moduleID == module.id))?true:false
                                    } />
                                    &nbsp; &nbsp; <label className="form-label mb-0">{module.name}</label>
                                    <div className="row" style={{display:"none"}}>
                                        {actions.map(action=>{
                                            return(
                                            <div className="col-md-3">
                                                <div className="mb-3">
                                                    <input type="checkbox" className={'actions_'+module.id}   name={"action_"+module.id+"_"+action.id} value={action.id} defaultChecked={
                                        (role.permissions.find((element) => (element.moduleID == module.id && element.actionID == action.id)))?true:false
                                    } />
                                                    &nbsp; &nbsp; <label className="form-label mb-0">{action.name}</label>
                                                </div>
                                            </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            )
                        })
                    }
                       
                        <hr/>
                        
                        <div className="col-lg-12 mt-3" style={{"text-align":"right"}}>                                    
                            <div  style={{display:'inline-block','vertical-align':"top"}}>
                                <button type="submit" className="btn btn-primary mx-2" >Submit</button>
                            </div>
                            <div style={{display:'inline-block','vertical-align':"top"}}>
                                <label> &nbsp; </label>
                                <button type="reset" className="btn btn-danger mx-2"  onClick={()=>{setModuleDisplay(false);}}>Cancel</button>
                            </div>
                        </div>
                    </form>
                </CModalBody>
            </CModal>
            </>
            </>) : (<></>)}

        </div>

    )
}

export default Role;