import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar'; 
import Footer from '../Layout/Footer';
// import Pagination from './Layout/Pagination';
import {DateFunc} from '../../services/Date';


import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { masterData } from '../../api/master-data';
import { putAPI,postAPI, uploadImageAPI,get, deleteAPI } from '../../services/ApiService';
import { apiBaseURL } from "../../Common/Constant";

function Study() {
    const [message, setMessage] = useState("");//for error handling   
    const [pageInfo, setPagination] = useState({});
    const [lists, setLists] = useState();//list of transactions
    const [startDate, setStartDate] = useState(new Date());//datepicker start date
    const [endDate, setEndDate] = useState(new Date());//datepicker end date



    const addStudy = async(e)=>{
        e.preventDefault();
        setMessage();

        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
        }
        // const formErrors = validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     //setErrors(formErrors)
        // }else{
            let res = await postAPI('/research/study/add', formInput);
            setMessage(res.message);
            if(res.status === 1){
                // Swal.fire({
                //     text:res.message,
                //     timer: 10000,
                // }).then(()=>{
                //     setVisibleDetail(false);
                //     getList();
                // })
                e.target.reset()
                 getList();
            }
        //}
    }


    const getList = async() =>{
        setMessage();
        
        let data = {page: pageInfo.current_page?pageInfo.current_page:0,recPerPage: 50, q: '',typeSlug:'primary-study'}
        let res = await get('/research/study/list', data);        
            if(res.status === 1){
                setLists(res.payload)
                setPagination(res.paginationInfo)
            }else{
            setLists([]);
            }
    }

    useEffect(()=>{
        getList();
    },[])


    const updateStudyStatus = async (ID,status) => {
        //setErrors();
        status = status==0?1:0;
        let formData = {
            ID,
            status:status
        };
         let res = await putAPI('/research/study/update-status/'+ID+'/'+status, formData);
            if(res.status === 1){
                getList();
               
             }
    }


    return (
        <>

            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                    {/* <div className='page-title p-4 pb-0'>
                        <h1 className='h5 m-0 text-uppercase'>Researche Database Access</h1>
                    </div> */}
                                
                                <div className='col-lg-12'>
                                        {/* <h2 className='h5 bg-light border-bottom border-primary border-4  p-3'>Upload Excel file</h2> */}
                                        <form class="p-3 row" onSubmit={addStudy}>
                                            <div className='p-1'>{message}</div>
                                <div class="col-md-3"  >
                                    <label>Study Name*</label>
                                                <input  class="form-control" type = 'text' name='name' required />
                                            </div>
                                <div class="col-md-3"  >
                                    <label>Project Lead*</label>
                                    <input  class="form-control" type = 'text' name='projectLead' required />
                                </div>
                                <div class="col-md-2"  >
                                    <label>Start Date*</label>
                                    <DatePicker className='form-control' selected={startDate} onChange={(date) => setStartDate(date)} name="startDate" id="startDate" dateFormat="d-MM-yyyy" />

                                </div>
                                <div class="col-md-2"  >
                                    <label>End Date*</label>
                                    <DatePicker className='form-control' selected={endDate} onChange={(date) => setEndDate(date)} name="endDate" id="endDate" dateFormat="d-MM-yyyy"  />

                                            </div>
                                <div class="col-md-2"  >
                                    <label>Sample Size*</label>
                                    <input  class="form-control" type = 'number' name='sampleSize'  required />
                                            </div>
                                <div class="col-md-10 mt-4"  ></div>
                                <div class="col-md-2 mt-4"  >
                                    <button  class="btn btn-md btn-success" type = 'submit' name=''>Submit</button>
                                    &nbsp; <button  class="btn btn-md btn-danger" type = 'submit' name=''>Reset</button>
                                            </div>
                                        </form>
                                    </div>
                                <hr/>

                                <div className="row mt-2">
                                    <div className="col-xl-12 col-lg-12">
                                        <div className="card">

                                            {/* <!-- end card header --> */}

                                            <div className="card-body">
                                                <table className="table table-striped table-hover table_client1" style={{"text-align":"left"}}>
                                                    <thead>
                                                        <tr className="bg-primary" style={{ color: "white" }}>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Study Name</th>
                                                <th scope="col">Project Lead</th>
                                                <th scope="col">Start Date</th>
                                                <th scope="col">End Date</th>
                                                <th scope="col">Sample Size</th>
                                                <th scope="col">Completed</th>
                                                <th scope="col">Partial</th>
                                                            <th scope="col align-item-right" style={{width:"150px"}}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    {(lists && lists.length > 0 )? (
                                                    <tbody>
                                                        {lists?.map((list,index) => (
                                                            <tr key={list?.ID}>
                                                            <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                            <td>{list.name}</td>
                                                <td>{list.projectLead}</td>
                                                <td>{DateFunc.format(list.startOn*1000,"dd mmm yyyy")}</td>
                                                <td>{DateFunc.format(list.endOn*1000,"dd mmm yyyy")}</td>
                                                <td>{list.sampleSize}</td>
                                                <td>{list.completedCount}</td>
                                                <td>{list.partialCount}</td>
                                                <td>
                                                <div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={list.status==1?"checked":""} onClick={()=>updateStudyStatus(list.ID,list.status)} />
                                                            </div>
                                                </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                    ):(<>
                                                        <tr>
                                                            <td colSpan={9} id="dataMessage" style={{padding:"50px 0"}}>No data found</td>
                                                        </tr>
                                                    </>)}
                                                </table>
                                                {/* <Pagination pageInfo={pageInfo} changePage={changePage} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                           

                </div>
                </div>
                </div>
                </div>
        </>
    );
};

export default Study;
