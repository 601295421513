export const expiryTime = (time = 900) => {
    let currentTime =  Math.floor(Date.now() / 1000)*1000;
    let after1Min = currentTime + (time*1000)
    return after1Min;
}

export const checkExpiry = (moduleName, subModuleName) => {
    let key = moduleName+(subModuleName ? subModuleName : '')
    let getExpiryTime = localStorage.getItem(key);
    if(getExpiryTime){
        let compair = JSON.parse(getExpiryTime).expiryTime - Math.floor(Date.now() / 1000)*1000
        if(compair > 0){
            return "Not Expired"
        }else{
            return "Expired"
        }
    }else{
        return "Expired";
    }
}