import React, { useState, useEffect, useRef } from 'react'
import { masterData } from '../api/master-data';
import * as User from '../api/users';
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import Swal from "sweetalert2";
import Pagination from './Layout/Pagination';
import { putAPI,postAPI, uploadImageAPI,get, deleteAPI } from '../services/ApiService';
import "react-datepicker/dist/react-datepicker.css";


function Transaction() {
    const [errors, setErrors] = useState({});//for error handling
    const [loadecheck, setLoadCheck] = useState(false);
    const [pageInfo, setPagination] = useState({});
    const dataFetchedRef = useRef(false);//used for preventing double loading logic
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();//list of transactions
    const [transaction, setTransactionDetail] = useState({});//detail of a transaction
    const [transactionType, setTransactionType] = useState('redeem-request');//detail of a transaction
    
    const [startDate, setStartDate] = useState(new Date());//datepicker start date
    const [endDate, setEndDate] = useState(new Date());//datepicker end date
    
    //Master User holders    
    const [users, setUsers] = useState();
    const [userId, setUserId] = useState('');
    //end User data holders

    useEffect(() => {
        document.title = "Transaction - GoToSample";
        getList();   
    },[transactionType, userId]);

    //UI/UX level and form control functions
    const setTransactionFields = (details)=>{
        //setVisibletransactionForm({visible:true,title:"Edit Transaction"});        
        setTransactionDetail(details);          
    }

    useEffect(() => {
        getActiveUserList();   
    },[]);

    const getActiveUserList = async() =>{
        let payloadData = {
            listType: 'active',
            q: "",
            page: 1, 
            recPerPage: 500,
            from: "",
            to:""
        }
        let res = await User.userList({payload: payloadData});
        if(res.status === 1){
            setUsers(res.payload);
        } else{
            setUsers([])
        }
    }


//START API level/called functions, considering to move to another file
    const getList = async() =>{
        setErrors();
        if(document.getElementById("dataMessage"))
        document.getElementById("dataMessage").innerHTML = "Loading . . .";
        if(transactionType == 'all' && userId == ''){
            setLists([]);
            setErrors({...errors,['filterError']:'Select one user!'})
            return false;
        }
        let data = {page: pageInfo.current_page?pageInfo.current_page:0,recPerPage: 50, q: searchKeyword,type:transactionType, userId}
        let res = await get('/transaction/list', data);        
            if(res.status === 1){
                setLists(res.payload)
                setPagination(res.paginationInfo)
            }else{
            setLists([]);
            Swal.fire({
                text:res.message,
                timer: 2000,
            })
        if(document.getElementById("dataMessage"))
            document.getElementById("dataMessage").innerHTML = res.message;
            }
    }

    const changePage = (p) => {
        pageInfo.current_page = p;
        getList();
    }
    

    const updateTransactionStatus = async (transactionId,status) => {
        // e.preventDefault();
        setErrors();
        let inputType = 'text';
        let title = 'Rejection of redemption?';
        let text = "Please enter reason of rejection!";
        if(status == 'approve'){
            inputType = 'file';
            title = 'Approval of redemption?';
            text = "Please enter reason of approval!";
        }
        Swal.fire({
            title,
            text,
            input: inputType,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit'
          }).then(async (result) => {            
            if (result.isConfirmed) {
                let remarks = '';
                if(status == 'reject'){
                    remarks = result.value;
                }else{
                    let res1 = await uploadImageAPI('/upload/common/', [result.value]);
                    remarks = res1.url;
                }
                
                let formData = {
                    ID:transactionId,
                    remarks: remarks,
                    status: status
                };      
                let res = await putAPI('/transaction/update-status/'+transactionId+'/', formData)
                if(res.status === 1){
                    Swal.fire(
                        'Success!',
                        'Redemption has been updated.',
                        'success'
                    )
                    getList();
                }else{
                    Swal.fire(
                        'Not Updated!',
                        'Redemption could be updated.',
                        'failure'
                      )
                }              
            }
          })        
    }  
    
    function decodeHtml(html) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }

// END API called functions

    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Manage Transaction</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                {/* <li className="breadcrumb-item"><a >Dashboards</a></li> */}
                                                <li className="breadcrumb-item active">Manage Transaction</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                          
                           
                            <form  id="transactionForm" className='row'>
                                <div id='point-message' style={{color:"red"}}>{errors?.filterError}</div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Transaction Type</label>                                        
                                        <select id="type" name='type' className="form-select" data-choices data-choices-sorting="true" onChange={(e)=>{setUserId('');setTransactionType(e.target.value)}} >
                                            <option value='redeem-request'  selected>Redeem Request</option>                                              
                                            <option value='redeem-approved'  >Redeem Approved</option>                                              
                                            <option value='redeem-rejected'  >Redeem Rejected</option>                                              
                                            <option value='earned'  >Earned Points</option>                                              
                                            <option value='all'  >All</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">User <span id='point-message' style={{color:"red"}}>{errors?.filterError}</span> </label>
                                        <select id="userId" name='userId' className="form-select" data-choices data-choices-sorting="true"  onChange={(e)=> setUserId(e.target.value)} >
                                            <option value={""}  selected>Select User</option>
                                            {users?.map((row) => {
                                                return (<option value={row.ID} selected={row.ID==userId?true:false} >{row.firstName} {row.lastName} - {row.email}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 mt-3">                                    
                                    <div  style={{display:'inline-block','vertical-align':"top"}}>
                                        <button type="submit" className="btn btn-primary mx-2" >Go</button>
                                    </div>
                                </div> */}
                            </form>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <table className="table table-striped table-hover table_client1" style={{"text-align":"left"}}>
                                                <thead>
                                                    <tr className="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">S.no.</th>
                                                        <th scope="col">User</th>
                                                        <th scope="col">Points</th>
                                                        { transactionType == 'all' && (
                                                            <td>Type</td>
                                                        )
                                                        }
                                                        <th scope="col">Source</th>
                                                        <th scope="col">Details</th>
                                                        <th scope="col">Date</th>
                                                        {/* <th scope="col">Status</th> */}
                                                        <th scope="col align-item-right" style={{width:"150px"}}>Action</th>
                                                    </tr>
                                                </thead>
                                                {(lists && lists.length > 0 )? (
                                                <tbody>
                                                    {lists?.map((list,index) => (
                                                        <tr key={list?.ID}>
                                                        <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                        <td>{list.firstName} {list.lastName} <br/><i className='mdi mdi-email'></i>: {list.email}  <br/><i className='mdi mdi-cellphone'></i>: {list.phone}</td>
                                                        <td>{list.points}</td>
                                                        { transactionType == 'all' && (
                                                            <td>{list.transTypeText} </td>

                                                        )
                                                        }
                                                        <td>{list.sourceType}</td>

                                                        <td>{list.remarks}<br/><b>{list.sourceDetail}</b></td>
                                                        <td>{list.transactionDate}</td>
                                                        {/* <td><div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={list.status==1?"checked":""} onClick={()=>updateTransactionStatus(list.ID,list.status)} />
                                                            </div></td> */}
                                                        <td className='align-item-right'>
                                                           {list.transType == 2 ?(
                                                                <div className="dropdown align-items-right">
                                                                    <div className="dropdown ms-sm-3 header-item topbar-user">
                                                                        <button type="button" className="btn btn-sm btn-success" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{'font-size':"14px"}}>
                                                                        More <i className="mdi mdi-dots-vertical-circle" ></i>
                                                                        </button>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>updateTransactionStatus(list.ID,'approve')} ><i className="mdi mdi-check"></i> Approve</a>
                                                                            <div className="dropdown-divider"></div>
                                                                            <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>updateTransactionStatus(list.ID,'reject')} ><i className="mdi mdi-block-helper"></i> Reject</a>                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ):(
                                                                <>
                                                                    {list.transType == 3 && (
                                                                        <a target='_blank' href={list.adminResponse}><b>View Proof</b></a>
                                                                        )
                                                                    }
                                                                    {list.transType == 4 && (list.adminResponse)}
                                                                </>
                                                            )
                                                           }                                                                
                                                            
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                                ):(<>
                                                    <tr>
                                                        <td colSpan={9} id="dataMessage" style={{padding:"50px 0"}}>No data found</td>
                                                    </tr>
                                                </>)}
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <!-- end row--> */}



                            {/* <!-- end row --> */}

                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />

                       
        </div>

    )
}

export default Transaction;