import { postAPI, deleteAPI, putAPI } from "../services/ApiService";
import Swal from "sweetalert2";
export const questionSetList = async({page=0, recPerPage=50, searchKeyword, categoryID, productID=0}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword,
        categoryID: categoryID,
        productID: productID
    }
    let res = await postAPI('/question-set/list', data)
    return res;
}

export const questionSetCreate = async({payload}) => {
    let res = await postAPI('/question-set/add', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const questionSetDelete = async({id}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/question-set/delete/'+id)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const questionSetUpdateStatus = async({id, status}) => {
    return Swal.fire({
        text: 'Are you sure you want to update?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/question-set/update-status/'+id+'/'+status)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const validateFormData = (formData) => {
    //validates form data cereated ny new Formdata
    //expects form has proper label
    console.log(formData.keys())
    let newErrors ={};
    for (const [key, value] of formData) {
        console.log(1);
        if(document.getElementsByName(key)[0].hasAttribute('required1') && value == ""){
            newErrors[key] = 'This field is required';
        }
    }    
    return newErrors
}