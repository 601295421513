import React, { useState, useEffect } from 'react';
import { CModal, CModalHeader, CModalTitle, CModalBody } from '@coreui/react';

import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar'; 
import Footer from '../Layout/Footer';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { masterData } from '../../api/master-data';
import { putAPI,postAPI, uploadImageAPI,getDownloadFile,get, deleteAPI } from '../../services/ApiService';
import {DateFunc} from '../../services/Date';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Screener() {
    const [groups, setGroups] = useState();
    const [countries, setCountries] = useState();
    const [states, setStates] = useState();
    const [currencyCode, setCurrencyCode] = useState();
    const [employeesizes, setEmployeeSizes] = useState();
    const [revenuesizes, setRevenueSizes] = useState();
    const [industries, setIndustries] = useState();
    const [departments, setDepartments] = useState();
    const [designations, setDesignations] = useState();
    // const [comstatuses, setComStatus] = useState();
    // const [accessstatuses, setAccessStatus] = useState();
    const [respondentCount, setRespondentCount] = useState(0);
    // const [studyNames, setStudyNames] = useState([]);
    // const [message, setMessage] = useState([]);
     const [showDownload, setShowDownload] = useState(false);
    
    const [popUpDisplay, setPopUpDisplay] = useState(false);
    // const [appointmentDisplay, setAppointmentDisplay] = useState("none");
    // const [appointmentTime, setappointmentTime] = useState(new Date());//datepicker start date
    
    
    // const [genders, setGenders] = useState();
    // const [agegroups, setAgeGroups] = useState(); 
    // const [educations, setEducations] = useState(); 
    // const [relationships, setRelationships] = useState(); 
     //const [incomegroups, setIncomeGroups] = useState(); 

    const validationSchema = Yup.object().shape({
        selectedSkills: Yup.array().min(1, 'Select at least one skill').required('Skills are required'),
    });


    useEffect(() => {
        document.title = "Research - GoToSample"   
        //if(dataFetchedRef.current){ // to stop double loading of APIs, skips in first mount
           
        
            const getCountries = async() => {
                let res = await masterData({'moduleName':'country'})
                if(res.status === 1){
                    setCountries(res.payload)
                }
            };
            //getCountries();
            const getEmployeeSizes = async() => {
                let res = await masterData({moduleName:'employee-size'})
                if(res.status === 1){
                    setEmployeeSizes(res.payload)
                }
            }
            //getEmployeeSizes();
            const getRevenueSizes = async() => {
                let res = await masterData({moduleName:'company-revenue'})
                if(res.status === 1){
                    setRevenueSizes(res.payload)
                }
            }
            //getRevenueSizes();
            const getDepartments = async() => {
                let res = await masterData({moduleName:'departments'})
                if(res.status === 1){
                    setDepartments(res.payload)
                }
            }
            //getDepartments()
            const getIndustries = async() => {
                let res = await masterData({moduleName:'industry'})
                if(res.status === 1){
                    setIndustries(res.payload)
                }
            }
            //getIndustries();
            const getDesignations = async() => {
                let res = await masterData({moduleName:'titles'})
                if(res.status === 1){
                    setDesignations(res.payload)
                }
            }
            //getDesignations();

            // const getStudyNames = async() => {
            //     let res = await get('/research/study/list',{status:1,current:1}); 
            //     if(res.status === 1){
            //         setStudyNames(res.payload)
            //     }
            // }
            // getStudyNames();

            // const getComStatus = async() => {
            //     let res = await masterData({moduleName:'communication-status'})
            //     if(res.status === 1){
            //         setComStatus(res.payload)
            //     }
            // }
            // getComStatus();

            // const getAccessStatus = async() => {
            //     let res = await masterData({moduleName:'database-access-status'})
            //     if(res.status === 1){
            //         setAccessStatus(res.payload)
            //     }
            // }
            // getAccessStatus();

        getFilters();
    },[]);

    const getStates = async(countryID) =>{
        setStates();

        let data = {countryID}
        let res = await get('/research/primary/states', data);        
            if(res.status === 1){
                setStates(res.payload.states.split("#"));
            }
    }
 
    const getFilters = async() =>{
        let data = {}
        let res = await get('/research/primary/filters', data);        
            if(res.status === 1){
                if(res.payload.countries != null)
                setCountries(res.payload.countries.split("#"));
                if(res.payload.groups != null)
                setGroups(res.payload.groups.split("#"));
                //setStates(res.payload.states);
                if(res.payload.revenues != null)
                    setRevenueSizes(res.payload.revenues.split("#"));
                if(res.payload.employee_sizes != null)
                    setEmployeeSizes(res.payload.employee_sizes.split("#"));
                
                if(res.payload.departments != null)
                setDepartments(res.payload.departments.split("#"));

                if(res.payload.industries != null)
                setIndustries(res.payload.industries.split("#"));

                if(res.payload.job_titles != null)
                setDesignations(res.payload.job_titles.split("#"));
            }else{
            }
    }

    useEffect(() => {
        loadRespondentCount();
    },[]);


    const filterRespondent = async(e) => {
        // Handle form submission
        //console.log(values.selectedSkills);
        e.preventDefault();
        loadRespondentCount();
        setShowDownload(true);
    };

    async function  loadRespondentCount(){
        const formData = new FormData(document.getElementById('filterForm'));
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;           
        }         
        let res = await get('/research/primary/get-record-numbers', formInput);        
        if(res.status === 1){
            setRespondentCount(res.payload.totalCount);
        }else{
            setRespondentCount(0);
        }
    }

    const downloadRespondents = async () => {
        // set search keyword            
        const formData = new FormData(document.getElementById('filterForm'));
        let qString = "";
        for (const [key, value] of formData) {
            qString += "&"+key+"="+value;           
        }  
        await getDownloadFile("/research/primary/download-respondents?page=1&recPerPage=10000"+qString, "respondents_"+Math.floor(new Date().getTime() / 1000));
};




    return (
        <>

            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                    {/* <div className='page-title p-4 pb-0'>
                        <h1 className='h5 m-0 text-uppercase'>Researche Database Access</h1>
                    </div> */}
                    <section className=''>
                        <div className='container'>
                            <div className='row g-3'>
                                
                                {/* left form start */}
                                <div className='col-lg-4'>
                                    <div className='form-theme included-label rounded-4 shadow'>
                                        <h2 className='h5 bg-light border-bottom border-primary border-4  p-2'> &nbsp; Choose Criteria</h2>
                                        {/* <Formik initialValues={{ selectedSkills: [] }} validationSchema={validationSchema} onSubmit={loadRespondents}> */}
                                        <form onSubmit={filterRespondent} className='p-3' id="filterForm">
                                            {/* <Form className='p-3'> */}
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className='row gy-1'>
                                                        <div className='col-12'>
                                                                <div className="form-group">
                                                                    
                                                                    {/* <select id="groupId" name='groupId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Group</option>
                                                                        {groups?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select>  */}

                                                                    {groups?.map((row,index) => {
                                                                                return (
                                                                                    <>
                                                                                <input type="radio" name='groupId' id={"groupId"+row} value={row} selected=''  style={{cursor:"pointer"}} />
                                                                                 &nbsp;<label for={"groupId"+row} style={{cursor:"pointer",paddingRight:(index==(groups.length-1))?"0":"40px"}}>{row}</label>
                                                                                </>
                                                                                )
                                                                            })}
                                                                    

                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Country</label>
                                                                    {/* <Select
                                                                        isMulti
                                                                        name="Country"
                                                                        options={countries}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    /> */}
                                                                    <select id="countryId" name='countryId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1" onChange={(e)=>getStates(e.target.value)} >
                                                                        <option value={""}  selected>Select Country</option>
                                                                        {countries?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="Country"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">State</label>
                                                                    {/* <Select
                                                                        isMulti
                                                                        name="stateId"
                                                                        options={[]}
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="mul-select"
                                                                    /> */}
                                                                    <select id="stateId" name='stateId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select State</option>
                                                                        {states?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="State"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-12'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Industry</label>
                                                                    <select id="industryId" name='industryId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Industry</option>
                                                                        {industries?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="Industry"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Employee Size</label>
                                                                    <select id="employeeSizeId" name='employeeSizeId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Employee Size</option>
                                                                        {employeesizes?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="EmployeeSize"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Revenue</label>
                                                                    <select id="revenueId" name='revenueId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Revenue</option>
                                                                        {revenuesizes?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="Revenue"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Job Title</label>
                                                                    <select id="jobTitleId" name='jobTitleId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Job Title</option>
                                                                        {designations?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select> 
                                                                    {/* <ErrorMessage
                                                                        name="JobTitle"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            <div className='col-6'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Department</label>
                                                                    <select id="departmentId" name='departmentId' className="form-select" data-choices data-choices-sorting="true" b2b_required="1"  >
                                                                        <option value={""}  selected>Select Department</option>
                                                                        {departments?.map((row) => {
                                                                                return (<option value={row} selected='' >{row}</option>)
                                                                            })}
                                                                    </select>
                                                                    {/* <ErrorMessage
                                                                        name="Department"
                                                                        component="div"
                                                                        className="form-error text-danger"
                                                                    /> */}

                                                                </div>
                                                            </div>
                                                            {/* end col */}
                                                            
                                                            <div className='col-12'>
                                                            <button type="submit" className='w-100 my-3 btn btn-md rounded-2 btn-warning' >Submit</button>
                                                        </div>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            {/* </form> */}
                                        </form>
                                        {/* </Formik> */}
                                    </div>
                                </div>
                                {/* left form end */}
                                <div className='col-lg-8'>
                                    <div  className='card'>
                                    <div class="card-body" style={{padding:"180px 0",textAlign:"center",fontSize:"16px",opacity:"0.8"}}>

                                        TOTAL RECORDS : {respondentCount}  <br/><br/>
                                        {showDownload &&
                                        <button className='btn btn-info' style={{width:"200px", display:"inline-block"}} onClick={()=>downloadRespondents()}><i className='mdi mdi-download'></i> DOWNLOAD</button>
                                        }
                                    </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
                </div>
                </div>
                </div>
            <Footer/>

            {popUpDisplay === true ? (<>
                <>
            <CModal visible={popUpDisplay} backdrop={"static"} size={"xl"} onClose={() => setPopUpDisplay(false)} id='surveyModal'>
                <CModalHeader style={{ backgroundColor: "#405189", paddingBottom: "10px", margin: "-1px" }} closeButton={false} >
                    <CModalTitle style={{ color: "#fff", width: "100%" }}>
                        <span style={{ float: "right", cursor: "pointer" }} onClick={() => setPopUpDisplay(false)} > X </span>
                    </CModalTitle>
                </CModalHeader>
                <CModalBody>
                </CModalBody>
            </CModal>
            </>
            </>) : (<></>)}
        </>
    );
};

export default Screener;
