import React, { useState, useEffect } from 'react';
import { defaultPage } from '../Common/Constant';
import Util from '../services/Utilliteis';
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import Pagination from './Layout/Pagination';
import Preloader from './Layout/Preloader';
import Swal from "sweetalert2";
import { postAPI, deleteAPI, putAPI , get, getDownloadFile} from "../services/ApiService";

import { CategoryList, ProductList, QuestionList } from '../api/common';
import { questionSetList, questionSetCreate, questionSetDelete, questionSetUpdateStatus, validateFormData } from '../api/question-set';

function QuestionSet() {
    useEffect(() => {
        document.title = "Question Set - GoToSample";
    },);
    const [errors, setErrors] = useState({});
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPagination] = useState({});

    const [categories, setCategories] = useState();
    const [products, setProducts] = useState();
    const [categoryID, setCategoryID] = useState();
    const [productID, setProductID] = useState();
    
    const [questions, setQuestions] = useState();
    const [questionDetail, setQuestionSetDetail] = useState({});
    const [questionID, setQuestionID] = useState();
    
    
    const getList = async() =>{
        if(categoryID){
            // let objPaylod = {page: 0, searchKeyword: searchKeyword, categoryID: categoryID};
            // if(productID)
            // objPaylod.productID = productID;
            
            // let res = await questionSetList(objPaylod);
            let data = {
                page: pageInfo.current_page,
                recPerPage: 50,
                q: searchKeyword,
                categoryID: categoryID,
                productID: productID
            }
            let res = await get('/question-set/list', data)
            if(res.status === 1){
                setLists(res.payload)
                setPagination(res.paginationInfo)
            }else{
                setLists([])
            }
        }
        
    }

    const getCategories = async() => {
        if(categoryID === undefined){
            let res = await CategoryList();
            if(res.status === 1){
                setCategories(res.payload);
            }
        }
    }
    
    useEffect(() => {
        getList();
    },[categoryID, productID]);

    useEffect(() => {
        getCategories()
    },[]);

    useEffect(() => {
        getProducts()
    },[categoryID]);

    useEffect(() => {
        getQuestions()
    },[productID]);

    const getProducts = async() => {
        if(categoryID){
            let res = await ProductList({payload: '?categoryID='+categoryID});
            if(res.status === 1){
                setProducts(res.payload);
            }
        }
    }

    const getQuestions = async() => {
        if(productID){
            let res = await QuestionList();
            if(res.status === 1){
                setQuestions(res.payload);
            }
        }
    }

    function setQuestionSetInfo(data){
        setCategoryID(data.categoryID);
        setProductID(data.productID);
        setQuestionID(data.questionID);
        setQuestionSetDetail(data);
    }

    const allowNumeric = async (e) => {
        // allow only numberic value, delete, backspace, arrow left, arrow right, tab
        if(!/[0-9]/.test(e.key) && e.key !== 'Delete' && e.key !== 'Backspace' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Tab'){
            e.preventDefault();
        }
    }

    

    const submitMappedQuestion = async (e) => {
        e.preventDefault();
        setErrors()
        const formData = new FormData(e.target);
        let formInput = {};
        for (const [key, value] of formData) {
            formInput[key] = value;
        }
        // const formErrors = validateFormData(formData)
        // console.log(formErrors);
        // if(Object.keys(formErrors).length > 0){
        //     setErrors(formErrors)
        // }else{
            let res = {};
            if(questionDetail && questionDetail.ID){
                res = await putAPI('/question-set/update/'+questionDetail.ID, formInput);
        }else{
                res = await postAPI('/question-set/add', formInput);
            }
            if(res.status === 1){
                Swal.fire({
                    text:res.message,
                    timer: 1500,
                })
                getList();
                resetAll();    
                //e.target.reset();
            }else{
                setErrors({...errors,['submit']:res.message})
            }
            
        //}
    }
    const deleteQstSet = async (id) => {
        let res = await questionSetDelete({id:id})
        if(res.status === 1){
            getList()
        }
    }
    const updateQstSetStatus = async (id,status) => {
        status = status === 0 ? 1 : 0;
        let res = await questionSetUpdateStatus({id:id,status:status});
        if(res.status === 1){
            getList();            
        }
    }

    function setCatFilter(catId){
        setProductID();
        setCategoryID(catId);
        //document.getElementById('categoryFilter').value = catId;
    }

    function setProductFilter(prodId){
        setProductID(prodId)
        //document.getElementById('productFilter').value = prodId;
    }

    function resetAll(){
        setQuestionSetDetail({});
        //setProducts();
        setQuestions();
        //setCategoryID();
        //setProductID();
        setQuestionID();
    }

    const changePage =(p)=>{
        pageInfo.current_page = p;
        getList();
    }

    const downloadProductData = async (pID="",qID="") => {
        await getDownloadFile("/products/download/?categoryID="+categoryID+"&productID="+pID+"&questionMappingID="+qID+"&page=1&recPerPage=250", "products_"+Math.floor(new Date().getTime() / 1000));        
    };

    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Question Set </h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item">Home</li>
                                                <li className="breadcrumb-item active">Question Set</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="card">
                                <div className='card-body'>
                                <form id="questionMappingForm" onSubmit={submitMappedQuestion}>
                                   {/* novalidate */}
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Category <span className="required_field">*{errors?.categoryID}</span></label>
                                                <select name="categoryID" className="form-select" onChange={(e) => setCatFilter(e.target.value)} disabled={questionDetail.ID?true:false} required="1">
                                                    <option value={""}  selected>Select category</option>
                                                    {categories?.map((ctg) => (<option value={ctg.id} selected={ctg.id === categoryID ? true : false}>{ctg.name}</option>)
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label className="form-label mb-0"> Product <span className="required_field">*{errors?.productID}</span></label>
                                                <select name="productID" className="form-select" onChange={(e) => {setProductFilter(e.target.value);setProductID(e.target.value)}} disabled={categoryID ? (questionDetail.ID?true:false) : true}>
                                                    <option value={""}  selected={productID ? false : true}>Select product</option>
                                                    {products?.map((prd) => (<option value={prd.id} selected={prd.id == questionDetail.productID ? true : false}>{prd.name}</option>)
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="mb-3">
                                                <label className="form-label mb-0"> Question <span className="required_field">*{errors?.questionID}</span></label>
                                                <select name="questionID" className="form-select" onChange={(e) => setQuestionID(e.target.value)}
                                                    disabled={productID ? (questionDetail.ID?true:false) : true}>
                                                    <option value={""}  selected>Select question</option>
                                                    {questions?.map((qst) => {
                                                        return (<option value={qst.ID} selected={qst.ID == questionDetail.questionID ? true : false}>{qst.title}: {qst.question}</option>)
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">B2C Points <span className="required_field">{errors?.questionB2CValue}</span></label>
                                                <input type="text" class="form-control" placeholder="Enter B2C points" maxLength={5} name="questionB2CValue" disabled={categoryID && productID && questionID ? false:true}  onKeyDown={(e) => allowNumeric(e)}  defaultValue={questionDetail.questionB2CValue} required="1" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">B2B Points <span className="required_field">{errors?.questionB2BValue}</span></label>
                                                <input type="text" class="form-control" placeholder="Enter B2B points" maxLength={5} name="questionB2BValue" disabled={categoryID && productID && questionID ? false:true}  onKeyDown={(e) => allowNumeric(e)} defaultValue={questionDetail.questionB2BValue}  required="1" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label className="form-label mb-0">Priority <span className="required_field">{errors?.priority}</span></label>
                                                <input type="text" class="form-control"  placeholder="Enter Priority" maxLength={5} name="priority" disabled={categoryID && productID && questionID ? false:true}  onKeyDown={(e) => allowNumeric(e)} defaultValue={questionDetail.priority} required="1" />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="mt-4 text-end">
                                            <input type="hidden" name="ID"  defaultValue={questionDetail.ID} />
                                                <button type="submit" className="btn btn-primary mx-3" >Submit</button>
                                                <button type="reset" className="btn btn-danger" onClick={()=>resetAll()}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                </div>
                            </div>
                            
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                        <div className='row'> 
                                                <div className="col-md-2">
                                                    <div className="mb-3">
                                                        {/* <label className="form-label mb-0">Category</label> */}
                                                        {/* <select className="form-select" data-choices data-choices-sorting="true" id="categoryId" onChange={(e)=> setCategoryId(e.target.value)} >
                                                            <option value={""} disabled selected>Select Category</option>
                                                            {categories?.map((row) => {
                                                                return (<option value={row.id} selected={row.id === categoryId ? true : false}>{row.name}</option>)
                                                            })}
                                                        </select> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-10" style={{"textAlign":"right"}}>
                                                    <div className="mb-3">
                                                        <button className="btn btn-primary " onClick={()=>downloadProductData(productID)}>
                                                            <i className="mdi mdi-download"></i> Download
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            {/* <div className='table-responsive table-scroll-fixed'> */}
                                                <table className="table table-striped table-hover table_client">
                                                    <thead>
                                                        <tr className="bg-primary" style={{ color: "white" }}>
                                                            <th scope="col">S.no.</th>
                                                            <th scope="col">Category</th>
                                                            <th scope="col">Product</th>
                                                            <th scope="col">Question no.</th>
                                                            <th scope="col" style={{textAlign:"left"}}>Question</th>
                                                            <th scope="col" style={{textAlign:"left"}}>Control Type</th>
                                                            <th scope="col" style={{textAlign:"left"}}>Available Options</th>

                                                            <th scope="col" style={{textAlign:"left"}}>B2C Points</th>
                                                            <th scope="col" style={{textAlign:"left"}}>B2B Points</th>
                                                            <th scope="col" style={{textAlign:"left"}}>Status</th>
                                                            <th scope="col" style={{textAlign:"left"}}>Priority</th>
                                                            <th scope="col" style={{width:"120px"}}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {lists ? (<>
                                                            {lists?.length > 0 ? (<>
                                                                {lists?.map((row, index) => {
                                                                    return(<>
                                                                    <tr>
                                                                    <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>

                                                                        <td>{row?.categoryName}</td>
                                                                        <td>{row?.productName}</td>
                                                                        <td>{row?.questionTitle}</td>
                                                                        <td style={{textAlign:"left"}}>{row?.question}</td>
                                                                        <td style={{textAlign:"left"}}>{row?.controlTypeName}</td>

                                                                        {/* show comma separated options */}
                                                                        <td style={{textAlign:"left"}}>
                                                                            {row?.controlTypeSlug !== 'textbox' ? (<>
                                                                                {row?.mappedOptions?.map((item, i) => {
                                                                                    return (<>{item?.option}{i+1 !== row?.mappedOptions?.length ? ', ' : ''}</>)
                                                                                })}
                                                                            </>) : (<>
                                                                                <span>-</span>
                                                                            </>)}
                                                                        </td>
                                                                        
                                                                        <td style={{textAlign:"left"}}>{row?.questionB2CValue}</td>
                                                                        <td style={{textAlign:"left"}}>{row?.questionB2BValue}</td>
                                                                        <td style={{textAlign:"left"}}>
                                                                            <div class="form-check form-switch">
                                                                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={row.status === 1 ? "checked" : ""} onClick={()=>updateQstSetStatus(row.ID, row.status)} />
                                                                            </div>
                                                                        </td>
                                                                        <td style={{textAlign:"left"}}>{row?.priority}</td>
                                                                        <td style={{textAlign:"left"}}>
                                                                          
                                                                        <div class="dropdown">
                                                                            <div className="dropdown ms-sm-3 header-item topbar-user">
                                                                                <button type="button" className="btn btn-sm btn-success" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{'font-size':"14px"}}>
                                                                                More <i className="mdi mdi-dots-vertical-circle" ></i>
                                                                                </button>
                                                                                <div className="dropdown-menu dropdown-menu-end">
                                                                                    <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>{setQuestionSetInfo(row);Util.scrollToTop()}} ><i className="mdi mdi-pencil"></i> Edit</a>
                                                                                    <div className="dropdown-divider"></div>
                                                                                    <a className="dropdown-item" style={{cursor:"pointer"}} onClick={()=>deleteQstSet(row?.ID)} ><i className="mdi mdi-delete"></i> Delete</a>                                                                            
                                                                                    <div className="dropdown-divider"></div>                                                                                    
                                                                                    <a className="dropdown-item " style={{cursor:"pointer"}} onClick={()=>downloadProductData(productID, row?.ID)}>
                                                                                        <i className="mdi mdi-download"></i> Download
                                                                                    </a>                                
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        </td>
                                                                    </tr>
                                                                    </>)
                                                                })}
                                                            </>) : (<>
                                                                <tr>
                                                                    <td colSpan={12}>
                                                                        <center>No mapped question found</center>
                                                                    </td>
                                                                </tr>
                                                            </>)}
                                                        </>) : (<>
                                                            <tr>
                                                                <td colSpan={12}>
                                                                    <center>Please select category to view mapped questions</center>
                                                                </td>
                                                            </tr>
                                                        </>)}
                                                    </tbody>
                                                </table>
                                                <Pagination pageInfo={pageInfo} changePage={changePage} />

                                            {/* </div> */}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default QuestionSet;