import React, { useState, useEffect } from 'react';
import { defaultPage } from '../Common/Constant';
import { questionList, questionCreate, questionDetail, questionUpdate, questionDelete, questionCheckUniqueTitle, validateFormData } from '../api/question';
import { masterData } from '../api/master-data';
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import Pagination from './Layout/Pagination';
import { putAPI,postAPI, get, uploadImageAPI } from '../services/ApiService';

function Question() {
    useEffect(() => {
        document.title = "Question - GoToSample"
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPagination] = useState({});
    const [showOption, setShowOption] = useState(false)
    const [countries, setCountries] = useState();
    const [languages, setLanguages] = useState();
    const [controlType, setControlType] = useState()
    const [submitBtnTxt, setSubmitBtnTxt] = useState('Submit')
    const [queId, setQueId] = useState()
    const [countryId, setCountryId] = useState()
    const [languageId, setLanguageId] = useState()
    const [controlTypeId, setControlTypeId] = useState()
    const [title, setTitle] = useState();
    const [editFormTitle, setEditFormTitle] = useState()
    const [question, setQuestion] = useState()
    const [addOption, setAddOption] = useState()
    const [mappedOption, setMappedOption] = useState([])
    const [errors, setErrors] = useState({});

    useEffect(() => {
        getList();
    },[countryId, languageId])

    useEffect(() => {
        getCountries()
        // getLanguages()
        getControlType()
    },[])

    useEffect(() => {
        getLanguages()
    },[countryId])

    const getCountries = async() => {
        let res = await masterData({moduleName:'country'})
        if(res.status === 1){
            setCountries(res.payload)
        }
    }

    const getLanguages = async() => {
        let res = await masterData({moduleName:'language',countryID:countryId})
        if(res.status === 1){
            setLanguages(res.payload)
        }
    }

    const getControlType = async() => {
        let res = await masterData({moduleName:'control-type'})
        if(res?.status === 1){
            setControlType(res.payload)
        }
    }

    const getList = async() =>{
        if(countryId && languageId){
        let data = {
            page: pageInfo.current_page,
            recPerPage: 50,
            q: searchKeyword,
            countryID: countryId,
            languageID: languageId
        }

            //let res = await questionList({page:0, searchKeyword:searchKeyword, countryID: countryId, languageID: languageId});
            let res = await get('/question/list', data)

            if(res.status === 1){
                setLists(res.payload)
                setPagination(res.paginationInfo)
            }else{
                setLists([])
            }
        }
        
    }

    const handleControlType = (value) => {
        setShowOption(true)
        if (value === '' || value === null || value === '1272' || value === 1272) {
            setShowOption(false)
        }
        setControlTypeId(value)
    }

    const checkUnique = async () => {
        setErrors({...errors, ['title']: null})
        if(languageId && title && title !== editFormTitle){
            let data = {
                languageID: parseInt(languageId),
                title: title
            }
            let res = await questionCheckUniqueTitle({payload:data})
            if(res.status === 0){
                setErrors({...errors, ['title']: res.message})
            }
        }
    }

    const handleAddOption = (e) => {
        setAddOption(e.target.value)
        // setForm({...form, ['description']:e.target.value})
    }

    const removeMapped = (item) => {
        let mappedList = mappedOption.filter(maped => maped != item);
        setMappedOption(mappedList);
    }

    const moveToMappedOption = async () => {
        addOption?.split('\n').map(function (item, i) {
            if (item !== '' || item !== null) {
                if (mappedOption?.indexOf(item) === -1) {
                    setMappedOption((mappedOption) => [...mappedOption, item])
                }
            }

        })
        await setAddOption("")
    }

    const handlesubmit = async (e) => {
        // e.preventDefault();
        setErrors();
        let formData = {
            mappedOptions:mappedOption,
            countryID:countryId,
            languageID:languageId,
            controlTypeID:controlTypeId,
            title:title,
            question:question,
            description:null
        };
        const formErrors = validateFormData(formData)
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        }else{
            let res = await questionCreate({payload:formData});
            if(res.status === 1){
                setControlTypeId()
                setTitle()
                document.getElementById('title').value=""
                setQuestion("")
                document.getElementById('question').value=""
                setAddOption()
                setMappedOption([])
                getList();
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        }
    }
    const handleQueTitle = async (e) => {
        if(!/[0-9a-zA-Z_]/.test(e.key)){
            e.preventDefault();
        }
    }

    const handleUpdate = async (e) => {
        // e.preventDefault();
        setErrors();
        // let ops = []
        // mappedOption.forEach(ele => {
        //     ops.push(ele?.option)
        // });
        
        let formData = {
            mappedOptions: mappedOption,
            countryID:countryId,
            languageID:languageId,
            controlTypeID:controlTypeId,
            title:title,
            question:question,
            description:null
        };
        const formErrors = validateFormData(formData)
        if(Object.keys(formErrors).length > 0){
            setErrors(formErrors)
        }else{
            let res = await questionUpdate({id:queId, payload:formData});
            if(res.status === 1){
                setQueId()
                setControlTypeId()
                setTitle()
                document.getElementById('title').value=""
                setQuestion("")
                document.getElementById('question').value=""
                setAddOption()
                setMappedOption([])
                setSubmitBtnTxt('Submit')
                getList();
            }else{
                setErrors({...errors,['submit']:res.message})
            }
        }
    }

    const editForm = async(queId) => {
        let res = await questionDetail({payload:queId})
        if(res.status === 1){
            let data = res.payload;
            setQueId(data.ID)
            setCountryId(data.countryID)
            setLanguageId(data.languageID)
            setControlTypeId(data.controlTypeID)
            setTitle(data.title)
            setEditFormTitle(data.title)
            setQuestion(data.question);

            let opsDt = []
            data.mappedOptions.forEach(ele => {
                opsDt.push(ele?.option)
            });
            setMappedOption(opsDt)
            setSubmitBtnTxt('Update')
            
            let controlTypeID = data?.controlTypeID
            setShowOption(true)
            if(controlTypeID === '' || controlTypeID === null || controlTypeID === '1272' || controlTypeID === 1272){
                setShowOption(false)
            }
            setControlTypeId(controlTypeID);

            // scroll to top
            window.scrollTo(0, 0)
        }
    }

    const deleteRow = async (id) => {
        let res = await questionDelete({id:id})
        if(res.status === 1){
            getList()
        }
    }

    const changePage =(p)=>{
        pageInfo.current_page = p;
        getList();
    }

    const resetForm = () => {
        setQueId()
        // setCountryId()
        // setLanguageId()
        setControlTypeId()
        setTitle()
        document.getElementById('title').value=""
        setQuestion("")
        document.getElementById('question').value=""
        setAddOption("")
        setShowOption(false)
        setMappedOption([])
        setSubmitBtnTxt('Submit')
        setErrors({})
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">
                    <div className="page-content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Question</h4>
                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Home</a></li>
                                                <li className="breadcrumb-item active">Question</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        {/* <!-- end card header --> */}
                                        <div className="card-body">
                                            <div className="">
                                                <form>
                                                    <div className="row">
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0"> Country <span className="required_field">*{errors?.countryID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true"  onChange={(e)=> setCountryId(e.target.value)}>
                                                                    <option value={""} disabled selected>Select Country</option>
                                                                    {countries?.map((row) => {
                                                                        return (<option value={row.id} selected={row.id === countryId ? true : false}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0"> Language <span className="required_field">*{errors?.languageID}</span></label>
                                                                <select id="ForminputState" className="form-select" data-choices data-choices-sorting="true" 
                                                                    onChange={(e) => setLanguageId(e.target.value)}
                                                                    disabled={countryId ? false : true}>
                                                                    <option value={""} disabled selected>Select Language</option>
                                                                    {languages?.map((row) => {
                                                                        return (<option value={row.id} selected={row.id === languageId ? true : false}>{row.name}</option>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Control Type <span className="required_field">*{errors?.controlTypeID}</span></label>
                                                                <select className="form-select"
                                                                disabled={countryId && languageId ? false : true}
                                                                onChange={(e) => handleControlType(e.target.value)}
                                                                >
                                                                    <option value={""}>Select Control</option>
                                                                    {controlType?.map((row) => {
                                                                        return (<><option value={row.id} selected={row.id === controlTypeId ? true : false}>{row.name}</option></>)
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="title" className="form-label mb-0">Question no.<span className="required_field">*{errors?.title}</span></label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    placeholder="Enter question no." 
                                                                    id="title" 
                                                                    disabled={countryId && languageId ? false:true} 
                                                                    value={title}
                                                                    onKeyDown={(e) => handleQueTitle(e)}
                                                                    onChange={(e) => setTitle(e.target.value)} 
                                                                    onBlur={() => checkUnique()} />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="mb-3">
                                                                <label htmlFor="question" className="form-label mb-0">Question <span className="required_field">*{errors?.question}</span></label>
                                                                <input 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    placeholder="Enter Question" 
                                                                    id="question" 
                                                                    value={question}
                                                                    disabled={countryId && languageId ? false:true} 
                                                                    onChange={(e) => setQuestion(e.target.value)} />
                                                            </div>
                                                        </div>
                                                        
                                                        {showOption ? (<>
                                                            <div className="form_divide"><hr ></hr></div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label htmlFor="addOption" className="form-label mb-0">Add Option <span className="required_field">*{errors?.description}</span></label>
                                                                    <textarea className="form-control" id="addOption" rows="3" onChange={(e) => handleAddOption(e)} value={addOption}></textarea>
                                                                    <span className="max_char">Maximum limit of characters 250</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label mb-0">Mapped Option <span className="required_field">*{errors?.mappedOptions}</span></label>
                                                                    
                                                                    <div style={{height:"80px", overflowY:"scroll", border:"1px solid", padding: "0.5rem 0.9rem", borderColor:"#ced4da", borderRadius:"4px"}}>
                                                                    {mappedOption?.map((item) => {
                                                                        return (<><div style={{cursor:"pointer","padding":"5px"}} >{item} &nbsp; &nbsp; <span style= {{color:"red",border:"1px solid red","font=weight":"bold",cursor:"pointer",padding:"0 5px"}} onClick={() => removeMapped(item)}>x</span></div></>)
                                                                    })}
                                                                    </div>
                                                                    <span className="max_char">Maximum limit of characters 250</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-12">
                                                                <div className="text-center mb-3">
                                                                    <span className="btn add_qustion " style={{backgroundColor:"gray",color:"white", cursor:"pointer"}} onClick={() => moveToMappedOption()}>Add</span>
                                                                </div>
                                                            </div>
                                                        </>) : (<>
                                                            
                                                        </>)}                                                    
                                                        <div className="col-lg-12">
                                                            <div className="text-end">
                                                                {submitBtnTxt === 'Update' ? (
                                                                    <span style={{cursor:"pointer"}} className="btn btn-primary mx-4" onClick={() => handleUpdate()}>{submitBtnTxt}</span>
                                                                ) : (
                                                                    <span style={{cursor:"pointer"}} className="btn btn-primary mx-4" onClick={() => handlesubmit()}>{submitBtnTxt}</span>
                                                                )}

                                                                <a className="btn btn-danger" onClick={() => resetForm()}>
                                                                    <i className="mdi mdi-refresh"></i> Reset
                                                                </a>
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                {/* <!-- end col --> */}
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{color:"white"}}>
                                                        <th scope="col">Sr. No.</th>
                                                        <th>Action</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Question no.</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Question</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Control Type</th>
                                                        <th scope="col" style={{textAlign:"left"}}>Available Options</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                {lists ? (<>
                                                    {lists?.length > 0 ? (<>
                                                        {lists?.map((row, index) => {
                                                            return(<>
                                                            <tr>
                                                            <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>

                                                                <td>
                                                                    <span className="p-2 font-16" title="Edit question">
                                                                        <i className="ri-edit-2-line pointerC" onClick={() => editForm(row?.ID)}></i>
                                                                    </span>
                                                                    <span className="p-2 font-16" title="Delete question">
                                                                        <i className="ri-delete-bin-2-line del pointerC" onClick={() => deleteRow(row?.ID)}></i>
                                                                    </span>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>
                                                                    <strong><span style={{textAlign:"left"}}>{row?.title}</span></strong>
                                                                </td>
                                                                <td style={{textAlign:"left"}}>{row?.question}</td>
                                                                <td style={{textAlign:"left"}}>{row?.controlTypeName}</td>

                                                                {/* show comma separated options */}
                                                                <td style={{textAlign:"left"}} width="40%">
                                                                    {row?.controlTypeSlug !== 'textbox' ? (<>
                                                                        {row?.mappedOptions?.map((item, i) => {
                                                                            return (<>{item?.option}{i+1 !== row?.mappedOptions?.length ? ', ' : ''}</>)
                                                                        })}
                                                                    </>) : (<>
                                                                        <span>-</span>
                                                                    </>)}
                                                                </td>
                                                            </tr>
                                                            </>)
                                                        })}
                                                    </>) : (<>
                                                        <tr>
                                                            <td colSpan={6}>No data found</td>
                                                        </tr>
                                                    </>)}
                                                </>) : (<>
                                                
                                                </>)}
                                                    
                                                </tbody>
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Question