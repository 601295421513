import React from 'react'
import { useEffect, useState } from "react";
import { s3bucket, apiBaseURL } from '../../Common/Constant';
import Footer from '../Layout/Footer';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar'; 
import Pagination from '../Layout/Pagination';

import { putAPI,postAPI, get, uploadImageAPI } from '../../services/ApiService';

function Action() {
    useEffect(() => {
        document.title = "Action - GoToSample"
    },);
    const [categories, setAction] = useState([]);
    const [pageInfo, setPagination] = useState({});
    const [fileList, setFileList] = useState(null);
    const token = localStorage.getItem("token");
    const headers = {
        // 'Accept': 'application/json',
         'Content-Type': 'application/json',
        // 'Origin': '',
        // 'Host': 'api.producthunt.com'
        'Authorization': 'Bearer '+token+''
    };
    const changePage =(p)=>{
        pageInfo.current_page = p;
        getList();
    }
    const getList = async(page=1) => {
        let data = {page: pageInfo.current_page,recPerPage: 50, q: "",func:''}
        let res = await get('/urm/action/list', data);        
            if(res.status === 1){
               // let data = res;//.json();
                let respArr = [];
                    respArr = res.payload;
                    respArr.forEach((r, index) => { r.serial = index + 1; });
                
                 setAction(respArr);
                 setPagination(res.paginationInfo);
            }
      }


const submitAction = async (e) => {
    // e.preventDefault();
    //setErrors();
    let name = document.getElementById('actionName').value;
    //let imageURL = document.getElementById('actionFileUrl').value;
    let formData = {
        name:name,
       // imageURL:imageURL
    };
    // const formErrors = validateFormData(formData)
    // if(Object.keys(formErrors).length > 0){
    //     //setErrors(formErrors)
    // }else{
        let res = {};
        if(document.getElementById('actionId').value == ""){
            res = await postAPI('/urm/action/add/', formData);
        }else{
            let actionId = document.getElementById('actionId').value; 
            res = await putAPI('/urm/action/update/'+actionId, formData);
        }
        if(res.status === 1){
            setActionFields();
            getList();           
         }
        //else{
        //     //setErrors({...errors,['submit']:res.message})
        // }
    //}
}


const updateActionStatus = async (rolId,status) => {
    // e.preventDefault();
    //setErrors();
    status = status==0?1:0;
    let formData = {
        id:rolId,
        status:status
    };
    // const formErrors = validateFormData(formData)
    // if(Object.keys(formErrors).length > 0){
    //     //setErrors(formErrors)
    // }else{
        let res = await putAPI('/urm/action/update-status/'+rolId+'/'+status, formData);
        if(res.status === 1){
            getList();           
         }
        //else{
        //     //setErrors({...errors,['submit']:res.message})
        // }
    //}
}


const setActionFields = (id,name,url)=>{
    document.getElementById('actionId').value = id?id:'';
    document.getElementById('actionName').value = name?name:'';
    // document.getElementById('actionFileUrl').value = url?s3bucket+url:'';
    if(!id){
        document.getElementById('cancelButton').style.display = 'none';
        //document.getElementById('actionFile').value = '';
        
    }else{
        document.getElementById('cancelButton').style.display = 'inline-block';
    }
    // if(!url){        
    //     document.getElementById('imageView').style.display = 'none';
    //     document.getElementById('imageView').src = '';
    // }else{
    //     document.getElementById('imageView').style.display = 'inline-block';
    //     document.getElementById('imageView').src = s3bucket+url;
    // }
}


useEffect(() => {
getList()
}, [])

    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Add Action</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                {/* <li className="breadcrumb-item"><a >Dashboards</a></li> */}
                                                <li className="breadcrumb-item active">Add Action</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className='row'>
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <input type="hidden" className="form-control" id="actionId"  />

                                        <label className="form-label mb-0">Action </label>
                                        <input type="text" className="form-control" placeholder="Enter action name" id="actionName" />
                                    </div>
                                </div>
                                {/* <div className="col-md-4">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Image (Dimension: 200 X 200 pixels)</label>
                                        <input type="file" className="form-control" placeholder="type action image" id="actionFile" onChange={handleChange} multiple />
                                        <input type="hidden" className="form-control" id="actionFileUrl"  />
                                    </div>
                                </div> */}
                                <div class="col-md-4">
                                    <img class="mt-3" id="imageView" style={{display:'none',height:"50px", border:"2px solid grey"}} src ="" />
                                    <div class="mt-3" style={{display:'inline-block'}}>
                                        <label> &nbsp; </label>
                                        <button type="button" class="btn btn-primary mx-2" onClick={()=>submitAction()}>Submit</button>
                                    </div>
                                    <div class="mt-3" style={{display:'inline-block'}}>
                                        <label> &nbsp; </label>
                                        <button type="button" className="btn btn-danger mx-2" id="cancelButton" style={{display:'none'}} onClick={()=>setActionFields()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-12 col-lg-12">
                                    <div class="card">

                                        {/* <!-- end card header --> */}

                                        <div class="card-body">
                                            <table class="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr class="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">#</th>
                                                        {/* <th scope="col">Image</th> */}
                                                        <th scope="col">Action Name</th>
                                                        {/* <th scope="col">User Count</th> */}
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                {categories.length > 0 && (
                                                <tbody>
                                                    {categories.map((action,index) => (
                                                        <tr key={action.id}>
                                                        <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                        {/* <td><img style={{width:'100px'}} src={s3bucket+action.description.icon} /></td> */}
                                                        <td>{action.name}</td>
                                                        {/* <td>{action.productCount}</td> */}
                                                        <td>
                                                            <div class="form-check form-switch" style={{textAlign:"center"}}>
                                                                <input class="form-check-input" type="checkbox" action="switch" id="flexSwitchCheckDefault" checked={action.status==1?"checked":""} onClick={()=>updateActionStatus(action.id,action.status)} />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button class="btn btn-sm" onClick={()=>setActionFields(action.id,action.name)}>Edit </button>
                                                            
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                                )}
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />                                            
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <!-- end row--> */}



                            {/* <!-- end row --> */}

                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Action;