import { get } from "../services/ApiService";

export const CategoryList = async() => {
    let res = await get('/common/category-list/')
    return res;
}
export const ProductList = async({payload}) => {
    let res = await get('/common/product-list/'+payload);
    return res;
}
export const QuestionList = async() => {
    let res = await get('/common/question-list/');
    return res;
}