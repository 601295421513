import React, { useState, useEffect } from 'react';
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar'; 
import Footer from '../Layout/Footer';
import { putAPI,postAPI, uploadImageAPI,get, deleteAPI } from '../../services/ApiService';
import { apiBaseURL } from "../../Common/Constant";
import {DateFunc} from '../../services/Date';


function Respondents() {
    const [message, setMessage] = useState("");//for error handling
    const [histories, setHistoryList] = useState([]);//for error handling
    
    //const handleChange = async(e)=>{
    const submitForm = async(e)=>{
        e.preventDefault();        
       // let res1 = await uploadImageAPI('/research/upload/', e.target.files[0]);
        let formData = new FormData()
        formData.append('file', e.target.file.files[0])
        const resp = await postAPI('/research/primary/upload/',formData)
        //console.log(resp);
        setMessage(resp.message)
        getList();
    }

    const getList = async() =>{
        //setMessage();
        
        let data = {};//{page: pageInfo.current_page?pageInfo.current_page:0,recPerPage: 50, q: '',typeSlug:'primary-study'}
        let res = await get('/research/primary/get-upload-history', data);        
            if(res.status === 1){
                setHistoryList(res.payload)
            }else{
            setHistoryList([]);
            }
    }

    useEffect(()=>{
        getList();
    },[])


    return (
        <>

            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">
                    {/* <div className='page-title p-4 pb-0'>
                        <h1 className='h5 m-0 text-uppercase'>Researche Database Access</h1>
                    </div> */}
                    <section className=''>
                        <div className='container'>
                            <div className='row g-3'>
                                
                                <div className='col-lg-12'>
                                    <div className='form-theme included-label rounded-4 shadow' >
                                        {/* <h2 className='h5 bg-light border-bottom border-primary border-4  p-3'>Upload Excel file</h2> */}
                                        <form class="row p-3" onSubmit={submitForm}>
                                            <div className="col-4 mb-3">
                                                <label>Upload excel file</label>
                                                <input  class="form-control" type = 'file' name='file' //onChange={handleChange}
                                                 multiple required/>
                                                
                                            </div>
                                            <div className="col-2 mb-3 mt-4">
                                               <button type='submit' className='btn  btn-primary'>Submit</button>
                                            </div>
                                            <div className='col-4 p-2 mt-4'>{message}</div>
                                        </form>
                                    </div>
                                </div>
                                <hr/>

                                <div className="row mt-2">
                                    <div className=" col-md-6 col-md-offset-3 ">
                                        <div className="card">

                                            {/* <!-- end card header --> */}

                                            <div className="card-body">
                                                <table className="table table-striped table-hover table_client1" style={{"text-align":"left"}}>
                                                    <thead>
                                                        <tr className="bg-primary" style={{ color: "white" }}>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Uploaded Date & Time</th>
                                                            <th scope="col align-item-right" style={{width:"150px"}}>No. of Records</th>
                                                        </tr>
                                                    </thead>
                                                    {(histories && histories.length > 0 )? (
                                                    <tbody>
                                                        {histories?.map((list,index) => (
                                                            <tr key={list?.ID}>
                                                            <td>{index +1}</td>
                                                             
                                                            <td>{DateFunc.format(list.uploadID*1000,"dd mmm yyyy hh:mm ampm")}</td>
                                                            <td>{list.num_records}</td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                    ):(<>
                                                        <tr>
                                                            <td colSpan={9} id="dataMessage" style={{padding:"50px 0"}}>No data found</td>
                                                        </tr>
                                                    </>)}
                                                </table>
                                                {/* <Pagination pageInfo={pageInfo} changePage={changePage} /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>

                </div>
                </div>
                </div>
                </div>
            <Footer />

        </>
    );
};

export default Respondents;
