import React, { useState, useEffect, useRef } from 'react'
import { masterData } from '../api/master-data';
import { defaultPage, s3bucket } from '../Common/Constant';
import Footer from './Layout/Footer';
import Header from './Layout/Header';
import Sidebar from './Layout/Sidebar';
import { putAPI,postAPI, uploadImageAPI, getDownloadFile } from '../services/ApiService';
import Pagination from './Layout/Pagination';

function Product() {

    useEffect(() => {
        document.title = "Product - GoToSample"
    },);
    const [searchKeyword, setSearchKeyword] = useState();
    const [lists, setLists] = useState();
    const [pageInfo, setPagination] = useState({});
    const [categoryId, setCategoryId] = useState();
    const [categories, setCategories] = useState();
    const [product, setProductDetail] = useState({});
    const [imageUrl, setimageUrl] = useState('');
 
    useEffect(() => {
        getCategories()
    },[])

    const getCategories = async() => {
        let res = await masterData({moduleName:'product-category'})
        if(res.status === 1){
            setCategories(res.payload)
        }
    }

    useEffect(() => {
        getList();
    },[categoryId])


    const getList = async() =>{
        if(categoryId){
            let data = {
                page: pageInfo.current_page,
                recPerPage: 50,
                q: searchKeyword,
                categoryID: categoryId
            }            
            let res = await postAPI('/products/list', data);
            if(res.status === 1){
                setLists(res.payload)
                setPagination(res.paginationInfo)
            }else{
                setLists([])
            }
        }        
    }
    

    const changePage =(p)=>{
        pageInfo.current_page = p;
        getList();
    }

    const setFilter = (e)=>{
        document.getElementById('categoryId').value = e.target.value;
        setCategoryId(e.target.value);
    }


    const handleChange = async (e) => {
        let file = e.target.files;
        var fileElement = file[0];
        var fileExtension = "";
        if (fileElement.name.lastIndexOf(".") > 0) {
            fileExtension = fileElement.name.substring(fileElement.name.lastIndexOf(".") + 1, fileElement.name.length);
        }
        // if (fileExtension.toLowerCase() == "doc" || fileExtension.toLowerCase() == "docx" || fileExtension.toLowerCase() == "pdf" || fileExtension.toLowerCase() == "xls" || fileExtension.toLowerCase() == "xlsx") {
            //setfileerr("");
            let res = await uploadImageAPI('/upload/common/', file)
            console.log('res', res)
            if (res.status === 1) {
                //setSelectedFile(res.url)
                //document.getElementById("productFileUrl").value = res.url;
                setimageUrl(res.url);
               
            }
    
        // }
        // else {
        //     setfileerr("You must select a doc/xls/pdf file for upload");
        //     return false;
        // }
    }
    
    
    const submitProduct = async (e) => {
        // e.preventDefault();
        //setErrors();
        let categoryId = document.getElementById('categoryIdInput').value;
        let name = document.getElementById('productName').value;
        let imageURL = imageUrl;//document.getElementById('productFileUrl').value;
        let formData = {
            categoryID:categoryId,
            name:name,
            imageURL:imageURL
        };
        // const formErrors = validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     //setErrors(formErrors)
        // }else{
        let res = {};
            if(!product || !product.id)
                res = await postAPI('/products/add', formData)
            else
                res = await putAPI('/products/update/'+product.id, formData)

            if(res.status === 1){
                setProductFields({});
                setimageUrl('');
                getList();           
             }
            //else{
            //     //setErrors({...errors,['submit']:res.message})
            // }
        //}
    }

    
    const updateProductStatus = async (productId,status) => {
        // e.preventDefault();
        //setErrors();
        status = status==0?1:0;
        let formData = {
            id:productId,
            status:status
        };
        // const formErrors = validateFormData(formData)
        // if(Object.keys(formErrors).length > 0){
        //     //setErrors(formErrors)
        // }else{
            let res = await putAPI('/products/update-status/'+productId+'/'+status, formData)

            if(res.status === 1){
                getList();
               
             }
            //else{
            //     //setErrors({...errors,['submit']:res.message})
            // }
        //}
    }
    
    const setProductFields = (details)=>{
        details.url = details.description?.icon ? details.description?.icon:"";
        setimageUrl(s3bucket+details.url);
        setProductDetail(details);
        if(details.categoryID)
        document.getElementById('categoryIdInput').value = details.categoryID;//?details.categoryID:'';
        document.getElementById('productName').value = details.name?details.name:'';
        // document.getElementById('productFileUrl').value = details.url;
        if(details.url=="")
        document.getElementById('productImage').value = "";

        document.getElementById('imageView').style.display = 'none';
        document.getElementById('imageView').src = '';
        document.getElementById('cancelButton').style.display = 'none';
        if(details.id){
            document.getElementById('cancelButton').style.display = 'inline-block';        
            document.getElementById('imageView').style.display = 'inline-block';
            document.getElementById('imageView').src = s3bucket+details.url;
        }
    }

     // drag and drop function 
        const dragItem = useRef();
        const dragOverItem = useRef();

        const dragStart = (e, position) => {
        dragItem.current = position;
        };
    
        const dragEnter = (e, position) => {
        dragOverItem.current = position;
        };
     
        const drop = (e) => {
        const dragItemContent = lists[dragItem.current];
        lists.splice(dragItem.current, 1);
        lists.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        setLists([...lists]);
        resetPrirority();
    };

    const resetPrirority=async()=>{
        let priorities = []
        // console.log(lists);

        lists.map(item=>{
            priorities.push(item.id);
        // console.log(item.id);

        })
        console.log(priorities);
        let res = await postAPI('/products/reset-priority', {priorities})
    }

    const downloadProductData = async (ID="") => {
        await getDownloadFile("/products/download/?categoryID="+categoryId+"&productID="+ID+"&page=1&recPerPage=250", "products_"+Math.floor(new Date().getTime() / 1000));        
    };
    return (
        <div>
            <Header />
            <Sidebar />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            {/* <!-- start page title --> */}
                            <div className="row">
                                <div className="col-12 col-lg-12 col-xl-12 col-md-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Add Product</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                {/* <li className="breadcrumb-item"><a >Dashboards</a></li> */}
                                                <li className="breadcrumb-item active">Add Product</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}
                            <div className='row'>
                                <div className="col-md-2">
                                    <div className="mb-3">
                                        <label className="form-label mb-0">Category</label>
                                        <select className="form-select" data-choices data-choices-sorting="true" id="categoryIdInput" onChange={setFilter} >
                                            <option value={""} disabled selected>Select Category</option>
                                            {categories?.map((row) => {
                                                return (<option value={row.id} selected={row.id === categoryId ? true : false}>{row.name}</option>)
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-2">
                                        <div className="mb-3">
                                            <label className="form-label mb-0">Product </label>
                                        <input type="text" className="form-control" placeholder="type product name ..." id="productName" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                        <div className="mb-3">
                                        <label className="form-label mb-0">Image ( Dimension: 200 X 200 pixels )</label>
                                        <input type="file" className="form-control" placeholder="type product image" id="productImage" onChange={handleChange} multiple />
                                        {/* <input type="hidden" className="form-control" id="productFileUrl"  /> */}
                                    </div>
                                </div>
                                <div className="col-lg-4 mt-3">
                                    <img class="" id="imageView" style={{display:'none',height:"40px", border:"2px solid grey",'vertical-align':"top"}} src ="" />
                                    <div className="" style={{display:'inline-block','vertical-align':"top"}}>
                                        <button type="submit" className="btn btn-primary mx-2" onClick={()=>submitProduct()}>Submit</button>
                                    </div>
                                    <div class="" style={{display:'inline-block','vertical-align':"top"}}>
                                        <label> &nbsp; </label>
                                        <button type="button" className="btn btn-danger mx-2" id="cancelButton" style={{display:'none'}} onClick={()=>setProductFields({})}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <div className='row'>
                                                <div className="col-md-2">
                                                    <div className="mb-3">
                                                        {/* <label className="form-label mb-0">Category</label> */}
                                                        <select className="form-select" data-choices data-choices-sorting="true" id="categoryId" onChange={(e)=> setCategoryId(e.target.value)} >
                                                            <option value={""} disabled selected>Select Category</option>
                                                            {categories?.map((row) => {
                                                                return (<option value={row.id} selected={row.id === categoryId ? true : false}>{row.name}</option>)
                                                            })}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-10" style={{"textAlign":"right"}}>
                                                    <div className="mb-3">
                                                        <button className="btn btn-primary " onClick={()=>downloadProductData()}>
                                                            <i className="mdi mdi-download"></i> Download
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="table table-striped table-hover table_client">
                                                <thead>
                                                    <tr className="bg-primary" style={{ color: "white" }}>
                                                        <th scope="col">S.no.</th>
                                                        <th scope="col">Product Image</th>
                                                        <th scope="col">Product Name</th>
                                                        <th scope="col">Category Name</th>
                                                        <th scope="col">Status</th>


                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                {lists && lists.length > 0 && (
                                                <tbody>
                                                    {lists?.map((list,index) => (
                                                        <tr key={list?.ID} onDragStart={(e) => dragStart(e, index-1)}
                                                                            onDragEnter={(e) => dragEnter(e, index-1)}
                                                                            onDragEnd={drop}
                                                                     draggable>
                                                        <td>{(pageInfo.current_page-1)*pageInfo.perPage + ++index}</td>
                                                        <td><img style={{width:'100px'}} src={s3bucket+list.description.icon} /></td>
                                                        <td>{list.name}</td>
                                                        <td>{list.categoryName}</td>
                                                        <td><div class="form-check form-switch">
                                                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={list.status==1?"checked":""} onClick={()=>updateProductStatus(list.id,list.status)} />
                                                            </div></td>
                                                        <td>
                                                            <button className="btn btn-sm" onClick={()=>setProductFields(list)}>Edit </button>
                                                            &nbsp; <button className="btn btn-sm " onClick={()=>downloadProductData(list?.id)}>
                                                            <i className="mdi mdi-download"></i></button>
                                                            
                                                        </td>
                                                    </tr>
                                                    ))}
                                                </tbody>
                                                )}
                                            </table>
                                            <Pagination pageInfo={pageInfo} changePage={changePage} />
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* <!-- end row--> */}



                            {/* <!-- end row --> */}

                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                    {/* <!-- End Page-content --> */}
                </div>
            </div>
            <Footer />
        </div>

    )
}

export default Product;