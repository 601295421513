import React from 'react'
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {get} from '../../services/ApiService';

function Sidebar() {
    const pathName = window.location.pathname;
    const [rolePermissions, setRolePermissions] = useState([]);


    const getList = async(page=1) => {
        let data = {page: 1,recPerPage: 500, q: "",func:''}
        let res = await get('/urm/role/get-permissions', data);        
        if(res.status === 1){
            // let data = res;//.json();
            setRolePermissions(res.payload);
        }
    }

    useEffect(() => {
        getList()
    }, [])
    return (
        <div id="layout-wrapper">

            <div className="app-menu navbar-menu">
                {/* <!-- LOGO --> */}
                <div className="navbar-brand-box">
                    {/* <!-- Dark Logo--> */}
                    <NavLink to={'/'} className="logo logo-dark">
                        <span className="logo-sm">
                            <img src="/assets/images/logo-big.png" alt="" style={{ width: "100%", height: "40px" }} />
                        </span>
                        <span className="logo-lg">
                            <img src="/assets/images/logo-big.png" alt="" style={{ width: "100%", height: "40px" }} />
                        </span>
                    </NavLink>
                    {/* <!-- Light Logo--> */}
                    <NavLink to={'/'} className="logo logo-light">
                        <span className="logo-sm">
                            <img src="/assets/images/logo-big.png" alt="" style={{ width: "100%", height: "40px" }} />
                        </span>
                        <span className="logo-lg">
                            <img src="/assets/images/logo-big.png" alt="" style={{ width: "100%", height: "40px" }} />
                        </span>
                    </NavLink>
                    <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover " >
                        <i className="ri-record-circle-line"></i>
                    </button>
                </div>
                <aside className='scrool_s'>
                    <div className='scrool'>
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <NavLink to={'/dashboard'} className="nav-link menu-link" >
                                    <i className="ri-dashboard-fill sideFirstTab"></i> <span data-key="t-dashboards sideFirstTabFont mx-2">Dashboard</span>
                                </NavLink>
                            </li>
                            {


                                (rolePermissions.find((element) => element.slug == 'panelist'))?(
                            <>
                            <li className ="nav-item">
                                <a className ="nav-link menu-link" href="#Panelist" data-bs-toggle="collapse" role="button" aria-expanded={(pathName==='/panelist/active' || pathName==='/panelist/incomplete' || pathName === '/panelist/delete-request' || pathName === '/panelist/deleted') ? 'true' : 'false'} aria-controls="Panelist">
                                    <i className ="ri-user-fill"></i> <span data-key="t-authentication">Panelist</span>
                                </a>
                                <div className={(pathName==='/panelist/active' || pathName==='/panelist/incomplete' || pathName === '/panelist/delete-request' || pathName === '/panelist/deleted') ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="Panelist">
                                    <ul className ="nav nav-sm flex-column">
                                        <li className ="nav-item">
                                            <NavLink to={'/panelist/active'} className ="nav-link"> Active </NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/panelist/incomplete'} className ="nav-link"> Incomplete </NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/panelist/delete-request'} className ="nav-link">Delete Request</NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/panelist/deleted'} className ="nav-link">Deleted</NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </li>
</>
    ):(<></>)

                                                        
                            }
                            
                             {(rolePermissions.find((element) => element.slug == 'research'))?( 
                             <li className ="nav-item">
                                <a className ="nav-link menu-link" href="#Datamanager" data-bs-toggle="collapse" role="button" aria-expanded={(pathName.includes('research')) ? 'true' : 'false'} aria-controls="Datamanager">
                                    <i className ="ri-user-fill"></i> <span data-key="t-authentication">Research</span>
                                </a>
                                <div className={(pathName.includes('research')) ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="Datamanager">
                                    <ul className ="nav nav-sm flex-column">
                                        {(rolePermissions.find((element) => element.slug == 'research-upload-respondent'))&&(
                                        <li className ="nav-item">
                                            <NavLink to={'/research/respondents'} className ="nav-link"> Upload Data</NavLink>
                                        </li>
                                         )} 
                                        {(rolePermissions.find((element) => element.slug == 'research-study'))&&(

                                        <li className ="nav-item">
                                            <NavLink to={'/research/study'} className ="nav-link"> Study </NavLink>
                                        </li>
                                         )} 

                                        {(rolePermissions.find((element) => element.slug == 'research-primary'))&&(

                                        <li className ="nav-item">
                                            <NavLink to={'/research/primary'} className ="nav-link"> Primary </NavLink>
                                        </li>
                                         )} 

                                        {/* <li className ="nav-item">
                                            <NavLink to={'#'} className ="nav-link"> Secondary </NavLink>
                                        </li> */}
                                        {(rolePermissions.find((element) => element.slug == 'research-report'))&&(
                                        
                                        <li className ="nav-item">
                                            <NavLink to={'/research/report'} className ="nav-link"> Report </NavLink>
                                        </li>
                                        )}
                                        <li className ="nav-item">
                                            <NavLink to={'/research/screener'} className ="nav-link"> Screener </NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/research/update'} className ="nav-link"> Update </NavLink>
                                        </li>

                                        {/* <li className ="nav-item">
                                            <NavLink to={'/primary-researcher-access'} className ="nav-link"> Primary Researcher </NavLink>
                                        </li> */}
                                        
                                    </ul>
                                </div>
                            </li>
                             ):(<></>)} 


<hr/>

    <li className ="nav-item">
    <a className ="nav-link menu-link" href="#Questionnaire" data-bs-toggle="collapse" role="button" aria-expanded={(pathName==='/category' || pathName==='/product' || pathName === '/question' || pathName === '/question-set') ? 'true' : 'false'} aria-controls="Questionnaire">
        <i className ="ri-user-fill"></i> <span data-key="t-authentication">Product Questionnaire</span>
    </a>
    <div className={(pathName==='/category' || pathName==='/product' || pathName === '/question' || pathName === '/question-set') ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="Questionnaire">
        <ul className ="nav nav-sm flex-column">  
       { (rolePermissions.find((element) => element.slug == 'categories'))?(
                            <li className="nav-item">
                                <NavLink to={'/category'} className="nav-link menu-link" >
                                    <i className="ri-apps-2-line"></i> <span data-key="t-apps">Categories</span>
                                </NavLink>
                            </li>
                            
    ):(<></>)

                                                        
                            }
                          {
                            (rolePermissions.find((element) => element.slug == 'products'))?(  
                            <li className="nav-item">
                                <NavLink to={'/product'} className="nav-link menu-link" >
                                    <i className="ri-add-circle-fill"></i> <span data-key="t-apps">Products</span>
                                </NavLink>
                            </li>
                            
                            ):(<></>)

                                                        
                        }

{
(rolePermissions.find((element) => element.slug == 'questions'))?(  
                            <li className="nav-item">
                                <NavLink to={'/question'} className="nav-link menu-link" >
                                    <i className="ri-pages-line"></i> <span data-key="t-apps">Questions</span>
                                </NavLink>
                            </li>
                            ):(<></>)

                                                        
                        }

{
(rolePermissions.find((element) => element.slug == 'question-mapping'))?( 
                        
                            <li className="nav-item">
                                <NavLink to={'/question-set'} className="nav-link menu-link" >
                                    <i className="ri-question-answer-fill"></i> <span data-key="t-apps">Question Mapping</span>
                                </NavLink>
                            </li>
                            ):(<></>)

                                                        
                        }
                        
                        </ul>
                            </div>
                            </li>
                        {
(rolePermissions.find((element) => element.slug == 'survey'))?( 

                            <li className="nav-item">
                                <NavLink to={'/survey-list'} className="nav-link menu-link" >
                                    <i className="ri-pencil-ruler-2-line"></i> <span data-key="t-apps">Surveys</span>
                                </NavLink>
                            </li>
                            ):(<></>)

                                                        
                        }

{
(rolePermissions.find((element) => element.slug == 'poll'))?( 
                        
                            <li className="nav-item">
                                <NavLink to={'/poll'} className="nav-link menu-link" >
                                    <i className="ri-apps-2-line"></i> <span data-key="t-apps">Poll</span>
                                </NavLink>
                            </li>
                            ):(<></>)

                                                        
}

{
(rolePermissions.find((element) => element.slug == 'redeem'))?( 

                            <li className="nav-item">
                                <NavLink to={'/redeem'} className="nav-link menu-link" >
                                    <i className="ri-picture-in-picture-2-line"></i> <span data-key="t-apps">Currency Value</span>
                                </NavLink>
                            </li>
                            ):(<></>)

                                                        
                        }

{
(rolePermissions.find((element) => element.slug == 'coupon'))?( 

                            <li className="nav-item">
                                <NavLink to={'/coupon'} className="nav-link menu-link" >
                                    <i className="ri-exchange-cny-line"></i> <span data-key="t-apps">Coupon</span>
                                </NavLink>
                            </li>
                             ):(<></>)

                                                        
                            }

{
(rolePermissions.find((element) => element.slug == 'donation'))?( 
                            <li className="nav-item">
                                <NavLink to={'/donation'} className="nav-link menu-link" >
                                    <i className="ri-account-circle-line"></i> <span data-key="t-apps">Donation</span>
                                </NavLink>
                            </li>

):(<></>)

                                                        
}

{
(rolePermissions.find((element) => element.slug == 'transaction'))?( 
                            <li className="nav-item">
                                <NavLink to={'/transaction'} className="nav-link menu-link" >
                                    <i className="ri-layout-3-line"></i> <span data-key="t-apps">Transaction</span>
                                </NavLink>
                            </li>
                            ):(<></>)

                                                        
                        }
                            <hr />
                            {/* <li className="nav-item">
                                <NavLink to={'/setting'} className="nav-link menu-link" >
                                    <i className="ri-share-line"></i> <span data-key="t-apps">Setting</span>
                                </NavLink>
                            </li> */}
                            
                            {
                            (rolePermissions.find((element) => element.slug == 'user-management'))?( 
                            <li className ="nav-item">
                                <a className ="nav-link menu-link" href="#sidebarAuth2" data-bs-toggle="collapse" role="button" aria-expanded={(pathName==='/urm/roles' || pathName==='/urm/modules' || pathName==='/urm/users') ? 'true' : 'false'} aria-controls="sidebarAuth2">
                                    <i className ="ri-user-fill"></i> <span data-key="t-authentication">Access Manager</span>
                                </a>
                                <div className={(pathName==='/urm/roles' || pathName==='/urm/modules' || pathName==='/urm/users') ? 'collapse menu-dropdown show' : 'collapse menu-dropdown'} id="sidebarAuth2">
                                    <ul className ="nav nav-sm flex-column">
                                        <li className ="nav-item">
                                            <NavLink to={'/urm/roles'} className ="nav-link"> Roles </NavLink>
                            </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/urm/modules'} className ="nav-link"> Modules </NavLink>
                                        </li>
                                        <li className ="nav-item">
                                            <NavLink to={'/urm/users'} className ="nav-link"> Users </NavLink>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </li>
                            ):(<></>)

                                                        
                        }

                       
                        </ul>
                        {/* <!-- Sidebar --> */}
                    </div>
                </aside>
                <div className="sidebar-background"></div>
            </div>
        </div>
    )
}

export default Sidebar